/**
 * ADDI - UI-Foundation
 * Do not edit directly
 * Generated on Mon, 03 Mar 2025 01:53:50 GMT
 */

module.exports = {
  "LayoutAddiGridMobileGap": "16px",
  "LayoutAddiGridMobileGutter": "16px",
  "LayoutAddiGridTabletGap": "32px",
  "LayoutAddiGridTabletGutter": "16px",
  "LayoutAddiGridDesktopGap": "44px",
  "LayoutAddiGridDesktopGutter": "24px",
  "LayoutAddiScreenMobileSmallMin": "320px",
  "LayoutAddiScreenMobileSmallMax": "359px",
  "LayoutAddiScreenMobileMediumMin": "360px",
  "LayoutAddiScreenMobileMediumMax": "389px",
  "LayoutAddiScreenMobileLargeMin": "390px",
  "LayoutAddiScreenMobileLargeMax": "767px",
  "LayoutAddiScreenTabletMin": "768px",
  "LayoutAddiScreenTabletMax": "1023px",
  "LayoutAddiScreenDesktopMin": "1024px",
  "LayoutAddiScreenDesktopMax": "1921px"
};