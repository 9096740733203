/**
 * ADDI - UI-Foundation
 * Do not edit directly
 * Generated on Mon, 03 Mar 2025 01:53:49 GMT
 */

module.exports = {
  "ColorAddiPrimaryActive": "rgb(60, 106, 240)",
  "ColorAddiPrimaryHover": "rgb(10, 57, 196)",
  "ColorAddiPrimaryInactive": "rgb(204, 221, 255)",
  "ColorAddiFeedbackSuccess": "rgb(0, 190, 122)",
  "ColorAddiFeedbackWarning": "rgb(239, 200, 0)",
  "ColorAddiFeedbackError": "rgb(250, 77, 75)",
  "ColorAddiFeedbackInfo": "rgb(133, 169, 255)",
  "ColorAddiFontPrimary": "rgb(24, 24, 31)",
  "ColorAddiFontSecondary": "rgb(77, 82, 92)",
  "ColorAddiFontStrong": "rgb(116, 119, 122)",
  "ColorAddiFontHint": "rgb(201, 205, 209)",
  "ColorAddiFontDisable": "rgb(235, 236, 237)",
  "ColorAddiFontOverDark": "rgb(255, 255, 255)",
  "ColorAddiFontAction": "rgb(60, 106, 240)",
  "ColorAddiFontSuccess": "rgb(1, 128, 82)",
  "ColorAddiFontError": "rgb(204, 31, 31)",
  "ColorAddiBackgroundWhite": "rgb(255, 255, 255)",
  "ColorAddiBackgroundSoft": "rgb(250, 250, 250)",
  "ColorAddiBackgroundMedium": "rgb(235, 236, 237)",
  "ColorAddiBackgroundHint": "rgb(201, 205, 209)",
  "ColorAddiBackgroundStrong": "rgb(116, 119, 122)",
  "ColorAddiCompOneLighten4": "rgb(250, 229, 255)",
  "ColorAddiCompOneLighten3": "rgb(247, 214, 255)",
  "ColorAddiCompOneLighten2": "rgb(236, 193, 247)",
  "ColorAddiCompOneLighten1": "rgb(221, 169, 235)",
  "ColorAddiCompOneBase": "rgb(195, 143, 208)",
  "ColorAddiCompOneDarken1": "rgb(168, 113, 189)",
  "ColorAddiCompOneDarken2": "rgb(139, 90, 163)",
  "ColorAddiCompOneDarken3": "rgb(105, 58, 132)",
  "ColorAddiCompOneDarken4": "rgb(70, 34, 102)",
  "ColorAddiCompOneDarken5": "rgb(41, 10, 76)",
  "ColorAddiCompTwoLighten5": "rgb(255, 253, 235)",
  "ColorAddiCompTwoLighten4": "rgb(255, 251, 214)",
  "ColorAddiCompTwoLighten3": "rgb(255, 245, 173)",
  "ColorAddiCompTwoLighten2": "rgb(255, 239, 138)",
  "ColorAddiCompTwoLighten1": "rgb(250, 223, 70)",
  "ColorAddiCompTwoBase": "rgb(239, 200, 0)",
  "ColorAddiCompTwoDarken1": "rgb(227, 185, 0)",
  "ColorAddiCompTwoDarken2": "rgb(184, 141, 0)",
  "ColorAddiCompTwoDarken3": "rgb(133, 99, 7)",
  "ColorAddiCompTwoDarken4": "rgb(92, 63, 6)",
  "ColorAddiCompThreeLighten5": "rgb(235, 255, 239)",
  "ColorAddiCompThreeLighten4": "rgb(212, 252, 231)",
  "ColorAddiCompThreeLighten3": "rgb(166, 237, 199)",
  "ColorAddiCompThreeLighten2": "rgb(67, 224, 146)",
  "ColorAddiCompThreeLighten1": "rgb(0, 190, 122)",
  "ColorAddiCompThreeBase": "rgb(1, 128, 82)",
  "ColorAddiCompThreeDarken1": "rgb(9, 112, 75)",
  "ColorAddiCompThreeDarken2": "rgb(11, 82, 56)",
  "ColorAddiCompThreeDarken3": "rgb(20, 51, 40)",
  "ColorAddiCompThreeDarken4": "rgb(12, 31, 24)",
  "ColorAddiCompFourLighten5": "rgb(255, 242, 242)",
  "ColorAddiCompFourLighten4": "rgb(255, 211, 209)",
  "ColorAddiCompFourLighten3": "rgb(255, 167, 166)",
  "ColorAddiCompFourLighten2": "rgb(250, 77, 75)",
  "ColorAddiCompFourLighten1": "rgb(219, 44, 44)",
  "ColorAddiCompFourBase": "rgb(204, 31, 31)",
  "ColorAddiCompFourDarken1": "rgb(184, 22, 25)",
  "ColorAddiCompFourDarken2": "rgb(163, 23, 23)",
  "ColorAddiCompFourDarken3": "rgb(143, 24, 29)",
  "ColorAddiCompFourDarken4": "rgb(122, 24, 31)",
  "ColorAddiCompFourAccentLighten2": "rgb(248, 186, 173)",
  "ColorAddiCompFiveLighten4": "rgb(235, 242, 255)",
  "ColorAddiCompFiveLighten3": "rgb(204, 221, 255)",
  "ColorAddiCompFiveLighten2": "rgb(133, 169, 255)",
  "ColorAddiCompFiveLighten1": "rgb(78, 126, 255)",
  "ColorAddiCompFiveBase": "rgb(60, 106, 240)",
  "ColorAddiCompFiveDarken1": "rgb(32, 79, 214)",
  "ColorAddiCompFiveDarken2": "rgb(10, 57, 196)",
  "ColorAddiCompFiveDarken3": "rgb(15, 53, 163)",
  "ColorAddiCompFiveDarken4": "rgb(11, 35, 92)",
  "ColorAddiCompFiveDarken5": "rgb(9, 26, 66)",
  "ColorAddiGrayLighten5": "rgb(255, 255, 255)",
  "ColorAddiGrayLighten4": "rgb(250, 250, 250)",
  "ColorAddiGrayLighten3": "rgb(235, 236, 237)",
  "ColorAddiGrayLighten2": "rgb(201, 205, 209)",
  "ColorAddiGrayLighten1": "rgb(116, 119, 122)",
  "ColorAddiGrayBase": "rgb(77, 82, 92)",
  "ColorAddiGrayDarken1": "rgb(51, 53, 61)",
  "ColorAddiGrayDarken2": "rgb(38, 38, 46)",
  "ColorAddiGrayDarken3": "rgb(24, 24, 31)",
  "ColorAddiGrayDarken4": "rgb(7, 8, 10)",
  "ColorAddiBlack": "rgb(24, 24, 31)",
  "ColorAddiWhite": "rgb(255, 255, 255)"
};